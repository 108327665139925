// src/components/Footer.js
import React from 'react';
import { useTranslation } from 'react-i18next'; // 确保引入 useTranslation
import './Footer.css';

function Footer() {
  const { t } = useTranslation(); // 使用 useTranslation 获取 t 函数
  return (
    <footer className="footer">
      <p>{t('footer.contact')}</p> {/* 这里需要使用 footer.contact */}
    </footer>
  );
}

export default Footer;
