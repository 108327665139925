// src/components/ShowcaseSteps.js
import React from 'react';
import { useTranslation } from 'react-i18next';

function ShowcaseSteps() {
  const { t } = useTranslation();

  return (
    <div className="showcase-steps">
      <h2>{t('showcase.title')}</h2>
      <p>{t('showcase.description')}</p>
      <div className="steps-container">
        <div className="step">
          <h3>{t('showcase.step1.title')}</h3>
          <p>{t('showcase.step1.description')}</p>
          <ul>
            <li>{t('showcase.step1.option1')}</li>
            <li>{t('showcase.step1.option2')}</li>
          </ul>
        </div>
        <div className="step">
          <h3>{t('showcase.step2.title')}</h3>
          <p>{t('showcase.step2.description')}</p>
          <ul>
            <li>{t('showcase.step2.item1')}</li>
            <li>{t('showcase.step2.item2')}</li>
            <li>{t('showcase.step2.item3')}</li>
          </ul>
        </div>
        <div className="step">
          <h3>{t('showcase.step3.title')}</h3>
          <p>{t('showcase.step3.description')}</p>
          <ul>
            <li>{t('showcase.step3.item1')}</li>
            <li>{t('showcase.step3.item2')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ShowcaseSteps;
