import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function SalesContactForm() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [sendingStatus, setSendingStatus] = useState(''); // 用于跟踪发送状态

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendingStatus('sending'); // 开始发送时设置状态为 "sending"
    
    try {
      const response = await fetch('https://webduralga.com/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Email sent successfully');
        setFormSubmitted(true);
        setSendingStatus('success'); // 成功时设置状态为 "success"
      } else {
        console.error('Failed to send email');
        setSendingStatus('failed'); // 失败时设置状态为 "failed"
      }
    } catch (error) {
      console.error('Error:', error);
      setSendingStatus('failed'); // 捕获错误时设置状态为 "failed"
    }
  };

  return (
    <div className="sales-contact-form">
      <h2>{t('contactForm.title')}</h2>
      {formSubmitted ? (
        <p className="success-message">{t('contactForm.successMessage')}</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <label>
            {t('contactForm.name')}:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            {t('contactForm.phone')}:
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            {t('contactForm.email')} ({t('contactForm.optional')}):
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </label>

          <label>
            {t('contactForm.message')}:
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </label>

          <button type="submit">{t('contactForm.submit')}</button>
        </form>
      )}
      
      {/* 根据发送状态显示不同的提示消息 */}
      {sendingStatus === 'sending' && <p>{t('contactForm.sendingMessage')}</p>}
      {/*{sendingStatus === 'success' && <p>{t('contactForm.successMessage')}</p>}*/}
      {sendingStatus === 'failed' && <p>{t('contactForm.failedMessage')}</p>}
    </div>
  );
}

export default SalesContactForm;
