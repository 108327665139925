// src/pages/ProjectsPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // 导入国际化钩子
import './ProjectsPage.css';
import image1 from '../images/1.jpg';
import image2 from '../images/2.jpg';
import image3 from '../images/3.jpg';

function ProjectsPage() {
  const { t } = useTranslation(); // 使用国际化钩子

  return (
    <div className="projects-container">
      <h2 className="projects-title">{t('projectsPage.title')}</h2>
      <div className="projects-list">
        <Link to="/electronics" className="project-card">
          <img src={image1} alt={t('projectsPage.electronicsAlt')} />
          <h3>{t('projectsPage.electronicsTitle')}</h3>
        </Link>
        <Link to="/tourism" className="project-card">
          <img src={image2} alt={t('projectsPage.tourismAlt')} />
          <h3>{t('projectsPage.tourismTitle')}</h3>
        </Link>
        <Link to="/turkmen-services" className="project-card">
          <img src={image3} alt={t('projectsPage.cargoAlt')} />
          <h3>{t('projectsPage.cargoTitle')}</h3>
        </Link>
      </div>
    </div>
  );
}

export default ProjectsPage;
