// src/pages/TourismPage.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import '../electronics/ElectronicsPage.css';

function TourismPage() {
  const { t, i18n } = useTranslation();

  // 图片文件路径数组，根据语言切换
  const images = {
    tk: ['1_tk.jpg', '2_tk.jpg', '3_tk.jpg', '4_tk.jpg','5_tk.jpg'],
    ru: ['1_ru.jpg', '2_ru.jpg', '3_ru.jpg', '4_ru.jpg' , '5_ru.jpg'],
    en: ['1_en.jpg', '2_en.jpg', '3_en.jpg', '4_en.jpg', '5_ru.jpg']
  };

  const currentLang = i18n.language; // 获取当前语言
  const currentImages = images[currentLang] || images.en; // 默认英语

  return (
    <div className="tourism-page">
      {/* 图片展示区 */}
      <div className="image-gallery">
        {currentImages.map((image, index) => (
          <Zoom key={index} overlayBgColorEnd="rgba(0, 0, 0, 0.8)">
            <img
              src={require(`./${image}`)} // 根据当前语言显示图片
              alt={`Tourism Image ${index + 1}`}
              className="gallery-image"
            />
          </Zoom>
        ))}
      </div>

      {/* 联系方式 */}
      <div className="contact-info">
        <h3>{t('tourism.contactTitle')}</h3>
        <p>{t('tourism.contactEmail')}: öz-saýtyňyz@example.com</p>
        <p>{t('tourism.contactPhone')}: +123 456 789</p>
        <p>{t('tourism.contactWebsite')}: öz-saýtyňyz.com</p>
        <p>{t('tourism.contactAddress')}: Aşgabat, Balkan, Ahal, Mary, Lebap, Daşoguz, Turkmenistan</p>
      </div>
    </div>
  );
}

export default TourismPage;
