// src/components/Navbar.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './Navbar.css';
import logo from '../images/logo_tk.png';

function Navbar() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [langMenuOpen, setLangMenuOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);
  const toggleLangMenu = () => setLangMenuOpen(!langMenuOpen);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLangMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="logo-container">
        <Zoom overlayBgColorEnd="rgba(0, 0, 0, 0.8)">
          <img src={logo} alt="Logo" className="logo-icon" /> {/* 可放大的 logo */}
        </Zoom>
        <span className="logo-text">WebDuralga</span>
      </div>

      {/* 汉堡菜单 */}
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      {/* 导航菜单 */}
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li>
          <NavLink to="/" onClick={() => setIsOpen(false)}>{t('navbar.home')}</NavLink>
        </li>
        <li>
          <NavLink to="/projects" onClick={() => setIsOpen(false)}>{t('navbar.projects')}</NavLink>
        </li>
        <li>
          <NavLink to="/pricing" onClick={() => setIsOpen(false)}>{t('navbar.pricing')}</NavLink>
        </li>
        <li>
          <NavLink to="/sales-contact" onClick={() => setIsOpen(false)}>{t('navbar.sales_contact')}</NavLink>
        </li>
        <li>
          <NavLink to="/about" onClick={() => setIsOpen(false)}>{t('navbar.about')}</NavLink>
        </li>
      </ul>

      {/* 语言切换按钮 */}
      <div className="language-switcher" onClick={toggleLangMenu}>
        🌐
        {langMenuOpen && (
          <div className="language-menu">
            <button onClick={() => changeLanguage('tk')}>TK</button>
            <button onClick={() => changeLanguage('ru')}>RU</button>
            <button onClick={() => changeLanguage('en')}>EN</button>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
