// src/pages/AboutPage.js
import React from 'react';
import { useTranslation } from 'react-i18next'; // Import i18n hook
import './AboutPage.css'; // Import CSS file

function AboutPage() {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <div className="about-container">
      <div className="hero-section">
        <h2 className="about-title">{t('about.title')}</h2>
        <p className="about-description">
          <strong>{t('about.description')}</strong>
        </p>
      </div>

      <div className="content-section">
        <p className="about-text">
          {t('about.introduction')}
        </p>

        <h3 className="about-subtitle">{t('about.offer.title')}</h3>
        <ul className="about-list">
          <li>{t('about.offer.item1')}</li>
          <li>{t('about.offer.item2')}</li>
          <li>{t('about.offer.item3')}</li>
          <li>{t('about.offer.item4')}</li>
        </ul>

        <h3 className="about-subtitle">{t('about.mission.title')}</h3>
        <p className="about-text">
          {t('about.mission.description')}
        </p>

        <h3 className="about-subtitle">{t('about.why.title')}</h3>
        <ul className="about-list">
          <li>{t('about.why.item1')}</li>
          <li>{t('about.why.item2')}</li>
          <li>{t('about.why.item3')}</li>
          <li>{t('about.why.item4')}</li>
        </ul>
      </div>
    </div>
  );
}

export default AboutPage;
