// src/pages/electronics/ElectronicsPage.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './ElectronicsPage.css'; // 更新 CSS 文件路径

function ElectronicsPage() {
  const { t, i18n } = useTranslation();

  // 图片文件路径数组，根据语言切换
  const images = {
    tk: ['1_tk.png', '2_tk.png', '3_tk.png', '4_tk.png','5_tk.png', '6_tk.png', '7_tk.png'],
    ru: ['1_ru.png', '2_ru.png', '3_ru.png', '4_ru.png', '5_ru.png', '6_ru.png', '7_ru.png'],
    en: ['1_en.png', '2_en.png', '3_en.png', '4_en.png', '5_en.png', '6_en.png', '7_en.png'],
  };

  const currentLang = i18n.language; // 获取当前语言
  const currentImages = images[currentLang] || images.tk; // 默认英语

  return (
    <div className="electronics-page">
      <div className="image-gallery">
        {currentImages.map((image, index) => (
          <Zoom key={index} overlayBgColorEnd="rgba(0, 0, 0, 0.8)">
            <img
              src={require(`./${image}`)} // 根据当前语言显示图片
              alt={`Service ${index + 1}`}
              className="gallery-image"
            />
          </Zoom>
        ))}
      </div>
    </div>
  );
}

export default ElectronicsPage;
