// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // 引入 React Helmet 用于设置SEO
import Navbar from './components/Navbar';
import ImageCarousel from './components/Carousel';
import ProjectList from './components/ProjectList';
import Footer from './components/Footer';
import ProjectsPage from './pages/ProjectsPage';
import PricingPage from './pages/PricingPage';
import SalesContactPage from './pages/SalesContactPage'; 
import AboutPage from './pages/AboutPage';
import ElectronicsPage from './pages/electronics/Electronicspage';
import Turkmenservices from './pages/turkmen-services/turkmen-services';
import TourismPage from './pages/tourism/TourismPage';
import './App.css';

import ShowcaseSteps from './components/ShowcaseSteps';
import SalesContactForm from './components/SalesContactForm';

// 定义页面的SEO元数据，根据实际语言内容动态设置
const seoContent = {
  title: "WEBDURALGA",
  description: "Welcome to our showcase website where we offer various services and projects, available in Turkmen, Russian, and English.",
  keywords: "showcase, projects, gorkezme websayty, görkezme websaýt, gorkezme bsayty, turkmençe web sayt, sayt yasamak, portfolio, Turkmen, Russian, English",
  ogTitle: "WEBDURALGA",
  ogDescription: "Turkmen dilinde web saytlar yasamak, Explore our showcase website for a variety of projects and services in Turkmen, Russian, and English."
};

// SEO组件，统一处理页面的元数据
function SEO() {
  return (
    <Helmet>
      <title>{seoContent.title}</title>
      <meta name="description" content={seoContent.description} />
      <meta name="keywords" content={seoContent.keywords} />
      <meta property="og:title" content={seoContent.ogTitle} />
      <meta property="og:description" content={seoContent.ogDescription} />
      <meta property="og:url" content="https://duralga.com" />
      <link rel="canonical" href="https://duralga.com" />
      <link rel="alternate" href="https://duralga.com" hreflang="x-default" />
    </Helmet>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <MainContent />
        <Footer />
      </div>
    </Router>
  );
}

function MainContent() {
  const location = useLocation();

  return (
    <>
      <SEO /> {/* 所有页面都应用SEO优化 */}
      {location.pathname === '/' && (
        <>
          <ImageCarousel />
          <ShowcaseSteps /> {/* 在 Carousel 下方添加展示型网站步骤 */}
        </>
      )}

      <Routes>
        <Route path="/" element={
          <>
            <ProjectList />
            <SalesContactForm /> {/* 保留销售联系表单 */}
          </>
        } />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/sales-contact" element={<SalesContactPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/electronics" element={<ElectronicsPage />} />
        <Route path="/tourism" element={<TourismPage />} />
        <Route path="/turkmen-services" element={<Turkmenservices />} />
      </Routes>
    </>
  );
}

export default App;
