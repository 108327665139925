import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ru from './locales/ru.json';
import tk from './locales/tk.json';

// 初始化 i18next 配置
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      ru: { translation: ru },
      tk: { translation: tk },
    },
    lng: 'tk', // 默认语言
    fallbackLng: 'ru', // 如果找不到语言时使用的默认语言
    interpolation: {
      escapeValue: false, // React 已经安全处理了转义
    },
  });

export default i18n;
