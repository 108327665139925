// src/pages/PricingPage.js
import React from 'react';
import { useTranslation } from 'react-i18next'; // 引入useTranslation钩子
import './PricingTable.css'; // 引入CSS文件

function PricingPage() {
  const { t } = useTranslation(); // 使用useTranslation钩子获取`t`方法

  return (
    <div className="pricing-container">
      <h2>{t('pricing.title')}</h2> {/* 使用t方法翻译标题 */}

      {/* 基础套餐表格 */}
      <div className="pricing-table">
        <h3>{t('pricing.basic.title')}</h3> {/* 翻译基础套餐标题 */}
        <table>
          <tbody>
            <tr>
              <th>{t('pricing.basic.price.label')}</th> {/* 翻译价格标签 */}
              <td>{t('pricing.basic.price.value')}</td> {/* 翻译价格值 */}
            </tr>
            <tr>
              <th>{t('pricing.basic.clients.label')}</th>
              <td>{t('pricing.basic.clients.value')}</td>
            </tr>
            <tr>
              <th>{t('pricing.basic.pages.label')}</th>
              <td>{t('pricing.basic.pages.value')}</td>
            </tr>
            <tr>
              <th>{t('pricing.basic.features.label')}</th>
              <td>
                <ul>
                  <li>{t('pricing.basic.features.item1')}</li>
                  <li>{t('pricing.basic.features.item2')}</li>
                  <li>{t('pricing.basic.features.item3')}</li>
                  <li>{t('pricing.basic.features.item4')}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* 标准套餐表格 */}
      <div className="pricing-table">
        <h3>{t('pricing.standard.title')}</h3>
        <table>
          <tbody>
            <tr>
              <th>{t('pricing.standard.price.label')}</th>
              <td>{t('pricing.standard.price.value')}</td>
            </tr>
            <tr>
              <th>{t('pricing.standard.clients.label')}</th>
              <td>{t('pricing.standard.clients.value')}</td>
            </tr>
            <tr>
              <th>{t('pricing.standard.pages.label')}</th>
              <td>{t('pricing.standard.pages.value')}</td>
            </tr>
            <tr>
              <th>{t('pricing.standard.features.label')}</th>
              <td>
                <ul>
                  <li>{t('pricing.standard.features.item1')}</li>
                  <li>{t('pricing.standard.features.item2')}</li>
                  <li>{t('pricing.standard.features.item3')}</li>
                  <li>{t('pricing.standard.features.item4')}</li>
                  <li>{t('pricing.standard.features.item5')}</li>
                  <li style={{ color: 'red' }}>{t('pricing.standard.features.item6')}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PricingPage;
