// src/components/ProjectList.js
import React from 'react';
import './ProjectList.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import image1 from '../images/1.jpg';
import image2 from '../images/2.jpg';
import image3 from '../images/3.jpg'; 

function ProjectList() {
  const { t } = useTranslation(); // 使用 useTranslation 钩子

  const projects = [
    {
      id: 1,
      name: t('projectList.projects.1.name'),
      path: '/electronics',
      description: t('projectList.projects.1.description'),
      image: image1,
    },
    {
      id: 2,
      name: t('projectList.projects.2.name'),
      path: '/tourism',
      description: t('projectList.projects.2.description'),
      image: image2,
    },
    {
      id: 3,
      name: t('projectList.projects.3.name'),
      path: '/turkmen-services',
      description: t('projectList.projects.3.description'),
      image: image3,
    },
  ];

  return (
    <div className="project-list">
      {projects.map((project) => (
        <div key={project.id} className="project-card">
          <img src={project.image} alt={project.name} />
          <h3>{project.name}</h3>
          <p>{project.description}</p>
          <Link to={project.path}>
            <button>{t('projectList.viewMore')}</button>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default ProjectList;
