// src/components/Carousel.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function ImageCarousel() {
  const { i18n } = useTranslation();

  // 根据当前语言选择相应的图片集
  const images = {
    tk: ['1_tk.jpg', '2_tk.jpg', '3_tk.jpg'],
    ru: ['1_ru.jpg', '2_ru.jpg', '3_ru.jpg'],
    en: ['1_en.jpg', '2_en.jpg', '3_en.jpg'],
  };

  const currentLang = i18n.language; // 获取当前语言
  const currentImages = images[currentLang] || images.en; // 默认英语

  return (
    <Carousel autoPlay infiniteLoop showThumbs={false}>
      {currentImages.map((image, index) => (
        <div key={index}>
          <img src={require(`../images/${image}`)} alt={`Slide ${index + 1}`} />
        </div>
      ))}
    </Carousel>
  );
}

export default ImageCarousel;
