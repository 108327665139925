import React, { useState } from 'react';
import { FaInstagram, FaTelegramPlane, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './SalesContactPage.css';

function SalesContactPage() {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [sendingStatus, setSendingStatus] = useState(''); // 用于跟踪发送状态

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendingStatus('sending'); // 开始发送时设置状态为 "sending"
    
    try {
      const response = await fetch('https://webduralga.com/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Email sent successfully');
        setFormSubmitted(true);
        setSendingStatus('success'); // 成功时设置状态为 "success"
      } else {
        console.error('Failed to send email');
        setSendingStatus('failed'); // 失败时设置状态为 "failed"
      }
    } catch (error) {
      console.error('Error:', error);
      setSendingStatus('failed'); // 捕获错误时设置状态为 "failed"
    }
  };

  const handleEmailClick = (event) => {
    event.preventDefault();
    const email = 'your-email@example.com';
    window.location.href = `mailto:${email}`;
  };

  return (
    <div className="sales-contact-page">
      <h2>{t('salesContact.title')}</h2>

      <div className="contact-info">
        <p>
          <FaPhoneAlt className="icon" />
          <span>{t('salesContact.Imo')}</span>
        </p>
        <p>
          <a href="#" onClick={handleEmailClick} className="email-link">
            <FaEnvelope className="icon" />
            <strong> Email:</strong>{' '}
            <span>webduralga@gmail.com</span>
          </a>
        </p>
        <p className="social-media">
          <a
            href="https://www.instagram.com/webduralga/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <FaInstagram className="icon" />
            <strong> Instagram:</strong>{' '}
            <span>@webduralga</span>
          </a>
        </p>
        <p className="social-media">
          <a
            href="https://t.me/webduralga"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <FaTelegramPlane className="icon" />
            <strong> Telegram:</strong>{' '}
            <span>@webduralga</span>
          </a>
        </p>
      </div>

      <div className="contact-form">
        {formSubmitted ? (
          <p className="success-message">{t('salesContact.successMessage')}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <label>
              {t('salesContact.Name')}
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              {t('salesContact.Phone')}
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              {t('salesContact.Email')}
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </label>

            <label>
              {t('salesContact.Message')}
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </label>

            <button type="submit">{t('salesContact.Submit')}</button>
          </form>
        )}

        {/* 根据发送状态显示不同的提示消息 */}
        {sendingStatus === 'sending' && <p>{t('salesContact.sendingMessage')}</p>}
        {/*{sendingStatus === 'success' && <p>{t('salesContact.successMessage')}</p>}*/}
        {sendingStatus === 'failed' && <p>{t('salesContact.failedMessage')}</p>}
      </div>
    </div>
  );
}

export default SalesContactPage;
